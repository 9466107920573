import React, {useState,useEffect} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {Alert, AlertTitle} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {useLocation} from "react-router-dom";
import DelegateDialog from "../DelegateDialog";

const ParcInstalle = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const [stationLoading,setStationLoading] = useState(false)
    const [listStation, setListStation] = useState(null)

    const location = useLocation()

    const init = () => {
        setStationLoading(true)

        let url = location.pathname === '/parc-installe' ?
            "/api/get-liste-station":
            "/api/get-liste-station-agent"

        apiGet(url)
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setListStation(data.data)
                }
            })
            .finally(() => {
                setStationLoading(false)
            })
    }

    const cancelDelegation = (equipmentCardNum) => {
        setStationLoading(true)
        apiPost('/api/cancel-delegate',{equipmentCardNum : equipmentCardNum})
            .then(data => {
                if(data.status !== 'error'){
                    init()
                }
            })
    }

    useEffect(() => {
        init()
    },[])

    return(
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Stations"
            page_active={location.pathname.substring(1)}
            dispatch={dispatch}
        >
            {
                stationLoading ?
                    <TableSkeleton nbCol={4} nbLig={6} />
                :
                    listStation !== null && listStation.station.length > 0 ?
                        <>
                            <TableData
                                columns={listStation.entete}
                                data={listStation.station}
                                defaultOrderColumnId='U_W3C_MESD'
                                dispatch={dispatch}
                                page_active={location.pathname.substring(1)}
                                cancelDelegation={cancelDelegation}
                            />
                            <DelegateDialog
                                init={init}
                            />
                        </>
                    :
                        <Alert severity="info">
                            Aucune station trouvée
                        </Alert>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(ParcInstalle)
